import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegmentGroup } from '@angular/router';

import { InjectorLocatorService } from 'src/app/injector-locator.service';

import { GenericsUtilities } from '@core/utilities/generics-utilities';

export class RouterUtilities {
  /**
   * @desc Closes the currently open router outlet.
   * @param {ActivatedRoute} activatedRoute Activated route instance.
   */
  static closeCurrentOutlet() {
    const router = InjectorLocatorService.injector.get(Router);

    const activatedOutlets = this.getActiveOutlets(router);
    // Close the last outlet.
    this.closeOutlet([...activatedOutlets.keys()].at(-1));
  }

  static closeOutlet(outletName: string): Promise<boolean> {
    const router = InjectorLocatorService.injector.get(Router);

    const currentUrlTree = router.parseUrl(router.routerState.snapshot.url);
    const newRootSegment = RouterUtilities.recursivelyClearOutlet(currentUrlTree.root, outletName);
    currentUrlTree.root = newRootSegment;

    return router.navigateByUrl(currentUrlTree);
  }

  private static recursivelyClearOutlet(root: UrlSegmentGroup, outletName: string): UrlSegmentGroup {
    const newChildren = {};
    for (const [childOutlet, child] of Object.entries(root.children)) {
      if (childOutlet !== outletName) {
        const newChild = this.recursivelyClearOutlet(child, outletName);
        newChildren[childOutlet] = newChild;
      }
    }
    return new UrlSegmentGroup(root.segments, newChildren);
  }

  /**
   * @desc Retrieve all active outlet. Note: this will not work with nested named outlet.
   */
  static getActiveOutlets(router: Router): Map<string, ActivatedRouteSnapshot> {
    const map: Map<string, ActivatedRouteSnapshot> = new Map<string, ActivatedRouteSnapshot>();

    // Process each child on the root snapshot.
    router.routerState.snapshot.root?.children?.forEach(child => {
      this.internalProcessActiveOutlets(map, child);
    });

    return map;
  }

  private static internalProcessActiveOutlets(
    map: Map<string, ActivatedRouteSnapshot>,
    routeSnapshot: ActivatedRouteSnapshot,
  ) {
    // The route was not found, or there are no keys.
    if (!routeSnapshot) {
      return map;
    }

    map.set(routeSnapshot.outlet, routeSnapshot);

    // Process the children of the current route snapshot.
    routeSnapshot.children.forEach(child => {
      this.internalProcessActiveOutlets(map, child);
    });

    return null;
  }

  /**
   * @desc Retrieve all data params and url params from object T.
   * @param {T} object Current route.
   * @param {Router | RouterStateSnapshot} entryPoint Either the router or router state snapshot instance.
   */
  static getApplicationRouteDataFromRouterStateSnapshot<T>(object: T, entryPoint: Router | RouterStateSnapshot): T {
    if (object === undefined) {
      console.log('did you forget to initialize T?');
      return undefined;
    }

    let rootSnapshot: ActivatedRouteSnapshot;
    //
    if (entryPoint instanceof Router) {
      // Root router state snapshot.
      rootSnapshot = entryPoint.routerState.snapshot.root;
    } else if (entryPoint instanceof RouterStateSnapshot) {
      // Root router state snapshot.
      rootSnapshot = entryPoint.root;
    }

    // Retrieve all of the keys along with their types.
    const map: Map<string, string> = new Map<string, string>(
      Object.keys(object).map(key => {
        const keyType = typeof GenericsUtilities.getProperty(object, key as keyof T);
        // Setting the key to undefined will allow to validate duplicates.
        (object as any)[key] = undefined;
        return [key, keyType];
      }),
    );

    // Process each child on the root snapshot.
    rootSnapshot?.children?.forEach(child => {
      this.internalProcessApplicationRouteDataFromRouterStateSnapshot(object, map, child);
    });

    // Return the result.
    return object;
  }

  private static internalProcessApplicationRouteDataFromRouterStateSnapshot<T>(
    object: T,
    map: Map<string, string>,
    routeSnapshot: ActivatedRouteSnapshot,
  ) {
    // The route was not found, or there are no keys.
    if (!routeSnapshot || !map.size) {
      return object;
    }

    // Loop through each key that represents an interest, and if they do exist add them to the map;
    map.forEach((type: string, key: string) => {
      // Validate if a key already exists.
      const existingKey = !!(object as any)[key];
      let hasValue = false;
      // Validate if a route parameter exists.
      const value = routeSnapshot.paramMap.get(key);
      if (value) {
        hasValue = true;
        (object as any)[key] = GenericsUtilities.getPropertyValue(type, value);
      }
      // Otherwise, look at the snapshot data.
      else {
        const data: any = routeSnapshot.data[key];
        if (data) {
          hasValue = true;
          const value = GenericsUtilities.getPropertyValue(type, data);
          (object as any)[key] = value;
          // If there is another key that ends with FromURL, try to retrieve the URL as well.
          const keyFromURL = `${key}FromURL`;
          if (map.has(keyFromURL)) {
            const typeFromURL = map.get(keyFromURL);
            const valueFromURL = routeSnapshot.paramMap.get(value);
            (object as any)[keyFromURL] = GenericsUtilities.getPropertyValue(typeFromURL, valueFromURL);
          }
        }
      }

      // A duplicate key was found which is not allowed.
      if (hasValue && existingKey) {
        throw new Error('Duplicate keys are not allowed');
      }
    });

    // Process the children of the current route snapshot.
    routeSnapshot.children.forEach(child => {
      this.internalProcessApplicationRouteDataFromRouterStateSnapshot(object, map, child);
    });

    return null;
  }

  /**
   * @desc T and dataKeys must be the same.
   * @param {ActivatedRoute} activatedRoute Current route.
   * @param {string | string[]} dataKeys Data keys to retrieve.
   */
  static getApplicationRouteDataFromSnapshot3<T>(object: T, activatedRoute: ActivatedRoute): T {
    if (object === undefined) {
      console.log('did you forget to initialize T?');
      return undefined;
    }

    // Retrieve all of the keys along with their types.
    const map: Map<string, string> = new Map<string, string>(
      Object.keys(object).map(key => [key, typeof GenericsUtilities.getProperty(object, key as keyof T)]),
    );
    // Process the current route.
    const result = this.internalGetApplicationRouteDataFromSnapshot3(object, activatedRoute, map);
    // Set the keys that were not found to undefined.
    map.forEach((type: string, key: string) => {
      (object as any)[key] = undefined;
    });

    // Return the result.
    return result;
  }

  private static internalGetApplicationRouteDataFromSnapshot3<T>(
    object: T,
    activatedRoute: ActivatedRoute,
    map: Map<string, string>,
  ): T {
    // The route was not found, or there are no keys.
    if (!activatedRoute || !map.size) {
      return object;
    }

    // Retrieve the snapshot.
    const snapshot = activatedRoute.snapshot;

    // Loop through each key that represents an interest, and if they do exist add them to the map and remove them from the keys array;
    map.forEach((type: string, key: string) => {
      // Validate if a route parameter exists.
      const value = snapshot.paramMap.get(key);
      if (value) {
        (object as any)[key] = GenericsUtilities.getPropertyValue(type, value);
        map.delete(key);
      }
      // Otherwise, look at the snapshot data.
      else {
        const data: any = snapshot.data[key];
        if (data) {
          (object as any)[key] = GenericsUtilities.getPropertyValue(type, data);
          map.delete(key);
        }
      }
    });

    // At least one key remains to be found, validate the immediate parent.
    if (map.size) {
      return this.internalGetApplicationRouteDataFromSnapshot3(object, activatedRoute.parent, map);
    }

    return object;
  }

  // static getParamFromSnapshot(activatedRoute: ActivatedRoute, key: string): any {
  //   // The route was not found.
  //   if (!activatedRoute) {
  //     return null;
  //   }

  //   // Retrieve the parameters on this snapshot.
  //   const snapshotParamMap = activatedRoute.snapshot.paramMap;
  //   // Validate if the key exists.
  //   const param = snapshotParamMap.get(key);
  //   // The key does not exist, validate the immediate parent.
  //   if (!param) {
  //     return this.getParamFromSnapshot(activatedRoute.parent, key);
  //   }

  //   return param;
  // }

  // static getSnapshots(activatedRoute: ActivatedRoute): any[] {
  //   return activatedRoute.snapshot.pathFromRoot.map((snapshot: { params: any; data: any }) => ({
  //     params: snapshot.params,
  //     data: snapshot.data,
  //   }));
  //   // .filter((item) => Object.keys(item.params).length !== 0);
  // }

  // static getSnapshotURLs(activatedRoute: ActivatedRoute) {
  //   activatedRoute.parent.snapshot.pathFromRoot
  //     .map((s) => s.url)
  //     .reduce((a, e) => {
  //       // Do NOT add last path!
  //       // if (a.length + e.length !== this._activatedRoute.parent.snapshot.pathFromRoot.length) {
  //       return a.concat(e);
  //       // }
  //       // return a;
  //     })
  //     .map((s) => s.path);
  // }
}
