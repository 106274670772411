import { Utilities } from '@core/utilities/utilities';

export class GenericsUtilities {
  static getProperty<T, K extends keyof T>(obj: T, key: K) {
    return obj[key];
  }

  static getPropertyValue(propertyType: string, propertyValue: any) {
    switch (propertyType) {
      case 'number':
        return Utilities.numberOrNull(propertyValue);
      case 'string':
        return propertyValue ?? undefined;
      default:
        return undefined;
    }
  }
}
